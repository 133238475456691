import React from "react";

function Copyright() {
  return (
    <>
      <h4>
        copyright &copy; {new Date().getFullYear()}
        <span> Foo Dev</span> all rights reserved
      </h4>
    </>
  );
}

export default Copyright;
