const links = [
  {
    id: 1,
    text: "home",
    url: "/",
  },
  {
    id: 2,
    text: "about",
    url: "#about",
  },
  {
    id: 3,
    text: "experience",
    url: "#experience",
  },
  {
    id: 5,
    text: "contact",
    url: "#contact",
  },
];

export default links;
